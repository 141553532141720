<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div class="card p-fluid">
          <h5>Address Data</h5>

          <div class="formgrid grid">
            <div class="field col-12">
              <label for="name2">Mail Owner</label>
              <InputText id="name2" type="text" v-model="form.owner_mail" />
            </div>
            <div class="field col-6">
              <label for="name2">Recepient Name</label>
              <InputText id="name2" v-model="form.receipent_name" type="text" />
            </div>
            <div class="field col-6">
              <label for="name2">Address Name</label>
              <InputText id="name2" v-model="form.address_name" type="text" />
            </div>
            <div class="field col-6">
              <label for="name2">phone</label>
              <InputText id="name2" v-model="form.phone" type="text" />
            </div>
            <div class="field col-6">
              <label for="name2">address</label>
              <InputText id="name2" v-model="form.address" type="text" />
            </div>
            <div class="field col-6">
              <label for="name2">address note</label>
              <InputText id="name2" v-model="form.note_address" type="text" />
            </div>
            <div class="field col-6">
              <label for="name2">country</label>
              <Dropdown
                id="inventoryStatus"
                v-model="form.country"
                :options="country_option"
                optionLabel="label"
                placeholder="Select a Status"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Dropdown>
            </div>
            <div class="field col-6">
              <label for="name2">province</label>
              <Dropdown
                id="inventoryStatus"
                v-model="form.province"
                :options="province_option"
                @change="GetProvince()"
                optionLabel="label"
                placeholder="Select a Status"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Dropdown>
            </div>
            <div class="field col-6">
              <label for="name2">city</label>
              <Dropdown
                id="inventoryStatus"
                v-model="form.city"
                :options="city_option"
                @change="GetProvince()"
                optionLabel="label"
                placeholder="Select a Status"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Dropdown>
            </div>
            <div class="field col-6">
              <label for="name2">state</label>
              <Dropdown
                id="inventoryStatus"
                v-model="form.state"
                :options="state_option"
                @change="GetProvince()"
                optionLabel="label"
                placeholder="Select a Status"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Dropdown>
            </div>
            <div class="field col-6">
              <label for="name2">zip code</label>
              <InputText id="name2" v-model="form.zip_code" type="text" />
            </div>
          </div>
          <div class="col-12">
            <Button label="Save" @click="Update()" class="p-button-outlined mr-2 mb-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FilterMatchMode } from "primevue/api";
import EmailApi from "../../api/Email";
import SettingApi from "../../api/Setting";
import moment from "moment";

export default {
  data() {
    return {
      editor: ClassicEditor,
      moment: moment,
      showModalCreate: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalEdit: false,
      selectedRole: {},
      deleteProductDialog: false,
      users: [],
      temp_user: {},
      country_option: [{ label: "Indonesia", value: "indonesia" }],
      province_option: [],
      city_option: [],
      state_option: [],
      roles_option: [],
      selectedUsers: [],
      isLoading: false,

      form: {
        receipent_name: null,
        address_name: null,
        phone: null,
        address: null,
        note_address: null,
        state: null,
        province: null,
        city: null,
        country: null,
        zip_code: null,
        owner_mail: null,
      },
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  async mounted() {
    await this.getRoles();
    if (this.form.country) {
      await this.GetLocation();
    }
  },

  methods: {
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async getRoles() {
      let get_data = await SettingApi.Get();

      if (get_data.data.success) {
        this.form = get_data.data.data.address;
        this.form.owner_mail = get_data.data.data.owner_mail;
      }
    },
    async Update() {
      try {
        this.isLoading = true;
        let updt_data = {
          ...this.form,
          country: this.form.country.value ? this.form.country.value : this.form.country,
          province: this.form.province.value ? this.form.province.value : this.form.province,
          city: this.form.city.value ? this.form.city.value : this.form.city,
          state: this.form.state.value ? this.form.state.value : this.form.state,
        };
        const response = await SettingApi.Add(updt_data);

        if (response.data.status === 200) {
          await this.getRoles();
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "data Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GetLocation() {
      if (this.form.country) {
        let get_country = await SettingApi.GetLocationByCountry(this.form.country);
        if (get_country.data.success) {
          this.province_option = get_country.data.data.provinsi.map((el) => {
            return {
              value: el,
              label: el,
            };
          });
        }
      }
    },
    async GetProvince() {
      if (this.form.province?.value) {
        let get_province = await SettingApi.GetLocationByProvince(this.form.province?.value);
        if (get_province.data.success) {
          let city_data = this.getUniqueListBy(get_province.data.data, "kota");
          let state_data = get_province.data.data.filter((el) => el.kota == this.form.city.value);
          this.state_option = state_data.map((el) => {
            return {
              value: el.kecamatan,
              label: el.kecamatan,
            };
          });
          this.city_option = city_data.map((el) => {
            return {
              value: el.kota,
              label: el.kota,
            };
          });
        }
      }
    },
    async deleteMultipleUser() {
      this.isLoading = true;
      try {
        //   reset selected users
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedUsers.length; i++) {
          payload.id.push(this.selectedUsers[i]._id);
        }

        const response = await EmailApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getUsers();
          this.isLoading = false;
          this.selectedUsers = [];
          this.showModalDelete = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_user = user;
    },
    async deleteItem(id) {
      try {
        this.isLoading = true;
        const response = await EmailApi.Delete(id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_user.username} Deleted!`,
            life: 3000,
          });
          this.getUsers();
          this.temp_user = {};
        }
      } catch (error) {
        console.log(error);
      }
    },
    confirmDeleteSelected() {
      this.showModalDelete = true;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
