import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/home");
  },
  //   getDetail(id) {
  //     return Api().get(`/user/${id}`);
  //   },
  //   GetOptionUserRole(role) {
  //     return Api().get(`/user/getuser?role=${role}`);
  //   },
  //   Update(id, data) {
  //     return Api().put(`/user/${id}`, data);
  //   },
  Add(data) {
    return Api().post(`/setaddress`, data);
  },
  GetLocationByCountry(country) {
    return Api().get(`/shipping/location?country=${country.toLowerCase()}`);
  },
  GetLocationByProvince(province) {
    return Api().get(`/shipping/location?province=${province.toLowerCase()}`);

  }
  //   Delete(id) {
  //     return Api().delete(`/user/${id}`);
  //   },

  //   UploadImage(id, data) {
  //     return Api().post(`user/upload/${id}`, data);
  //   },
};
